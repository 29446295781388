import React from 'react'
import JombotronImageMobile from "../../Images/Rectangle 2.png"
import Vector from "../../Images/Vector.png"
import StarIcon from "../../Images/VectorStar.png"

export const Jombotron = () => {
  return (
    <div className='bg-[#E5E5E5] px-6 lg:grid lg:grid-cols-2'>
        <div className='lg:ml-[100px] lg:mt-[40px]'>
            <p className='font-anton text-[36px] pt-[50px] lg:pt-[90px] text-center lg:text-left'>Payment reminders and more, all from just $5/month</p>
            <p className='text-[14px] text-center lg:text-left text-[#7D7C7C]'><b>For a limited time only, get more for less with MYOB: the all-in-one platform for all your business needs. Offer extended to 30 November!</b></p>
            <div className='flex justify-center lg:flex lg:justify-start pb-[90px]'>
              <button type="button" className="w-[300px] font-anton text-[16px] text-white bg-black py-3 rounded-[20px] mt-[20px]">Get the offer</button>
            </div>
        </div>
        <img src={Vector} className='w-[80%] ml-5 mb-5 hidden sm:hidden md:hidden lg:block' />
        {/* <img src={StarIcon} className='absolute mt-[-400px] right-0 mr-8' />
        <img src={StarIcon} className='absolute mt-[-300px] ml-8 h-[40px]' /> */}
    </div>
  )
}
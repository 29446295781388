import React from 'react'
import { BsGearWideConnected, BsBuildingFillGear, BsCheckLg } from "react-icons/bs"
import { FaUsersGear } from "react-icons/fa6"

export const FeaturesContent = () => {
  const benefitText = 'flex items-center text-white mt-5 mb-5'
  const buyButton = "w-full text-[15px] py-2 text-white bg-[#53BD00] rounded-[20px] mt-[60px] mb-5"
  const labelCard = "mb-2 text-2xl font-bold tracking-tight text-white flex items-center mt-5"
  const cardStyle = "block max-w-sm p-6 border border-[#E5E5E5] rounded-[20px] hover:bg-gray-100 transition-all duration-500 mx-3 shadow-md"
  const bgBasic = "bg-gradient-to-r from-cyan-500 to-blue-500"
  const bgStandard = "bg-gradient-to-r from-blue-500 to-purple-500"
  const bgInterprice = "bg-gradient-to-r from-purple-500 to-cyan-500"

  const PaketProduct = ({ label, type, price }) => (
    <a href="#" class={`${cardStyle} ${type === "basic" ? bgBasic : type === "standard" ? bgStandard : bgInterprice}`}>
      <h5 class={labelCard}>
        {type === "basic" ? <BsGearWideConnected className='mr-3' /> : type === "standard" ? <FaUsersGear className='mr-3' />  : <BsBuildingFillGear className='mr-3' />}
        {label}
      </h5>
      <div className='bg-white rounded-tr-[50px] rounded-br-[50px] ml-[-25px] text-left py-5 mt-5'>
        <div className='text-[25px] text-black text-left font-bold pl-6'>{price ? price : "Rp.0"}</div>
        <div className='text-black font-bold text-left pl-6 text-[15px] mt-[-10px]'>/month</div>
      </div>
      <div className={benefitText}><BsCheckLg className='mr-3' />Here are the biggest enterprise1</div>
      <div className={benefitText}><BsCheckLg className='mr-3' />Here are the biggest enterprise</div>
      <div className={benefitText}><BsCheckLg className='mr-3' />Here are the biggest enterprise</div>
      <div className={benefitText}><BsCheckLg className='mr-3' />Here are the biggest enterprise</div>
      <div className={benefitText}><BsCheckLg className='mr-3' />Here are the biggest enterprise</div>
      <div className={benefitText}><BsCheckLg className='mr-3' />Here are the biggest enterprise</div>
      <hr />
      <button type="button" className={buyButton} onClick={() => window.location.href = `https://erp.hawknusantara.com/signup?data=${type}`}><b>Buy Now</b></button>
    </a>
  )
  return (
    <div className='bg-white pt-5 pb-5'>
        <p className='text-[29px]'><b>Paket</b></p>
        <div className='flex justify-center mt-5 hidden sm:hidden md:hidden lg:block lg:flex lg:justify-center'>
            <PaketProduct label="Paket Basic" type="basic" price="Rp.120.000" />
            <PaketProduct label="Paket Standard" type="standard" price="Rp.250.000" />
            <PaketProduct label="Paket Enterprise" type="enterprise" price="Rp.370.000" />
        </div>
        {/* Responsive */}
        <div className='flex justify-center mt-5 block md:hidden lg:hidden'>
          <PaketProduct label="Paket Basic" type="basic" price="Rp.120.000" />
        </div>
    </div>
  )
}
import React from 'react'
import { AiOutlineMail, AiOutlineTwitter, AiOutlineInstagram, AiFillGithub } from "react-icons/ai"
import { BiLogoFacebook } from "react-icons/bi"
import Payment from "../../Images/BANK.png"

export const Footer = () => {
  const transitionAnimation = 'transition-all duration-300'
  const iconStyle = `h-[30px] w-[30px] border border-white rounded-[50%] mt-3 flex items-center justify-center mr-2 hover:text-white hover:bg-black ${transitionAnimation}`
  return (
    <div className='bg-black'>
        <div className='text-left px-6 pt-5'>
            <p className='text-[29px] text-white'><b>FLYING HAWK NUSANTARA</b></p>
            <p className='text-[14px] text-[#7D7C7C]'>We have clothes that suits your style and which you’re proud to wear. From women to men.</p>
        </div>

        {/* Sosmed Icon */}
        <div className='px-6 flex text-white'>
            <div className={iconStyle}>
                <AiOutlineTwitter />
            </div>
            <div className={iconStyle}>
                <BiLogoFacebook />
            </div>
            <div className={iconStyle}>
                <AiOutlineInstagram />
            </div>
            <div className={iconStyle}>
                <AiFillGithub />
            </div>
        </div>

        {/* Link */}
        <div className='grid grid-cols-4 ml-6 mt-5 mb-9 text-[13px] text-white'>
            <div className='text-left'>
                <p><b>COMPANY</b></p>
                <div className='text-[#7D7C7C] mt-3'>
                    <p>About</p>
                    <p>Features</p>
                    <p>Work</p>
                    <p>Career</p>
                </div>
                <p className='mt-3'><b>FAQ</b></p>
                <div className='text-[#7D7C7C] mt-3'>
                    <p>Account</p>
                    <p>Manage Deliveries</p>
                    <p>Orders</p>
                    <p>Payment</p>
                </div>
            </div>
            <div className='text-left'>
                <p><b>HELP</b></p>
                <div className='text-[#7D7C7C] mt-3'>
                    <p>Customer Support</p>
                    <p>Delivery Details</p>
                    <p>Terms & Conditions</p>
                    <p>Privacy Policy</p>
                </div>
                <p className='mt-3'><b>RESOURCE</b></p>
                <div className='text-[#7D7C7C] mt-3'>
                    <p>Free eBook</p>
                    <p>Development Tutorial</p>
                    <p>How to - Blog</p>
                    <p>Youtube Playlist</p>
                </div>
            </div>
            <div className='text-left'>
                <p><b>COMPANY</b></p>
                <div className='text-[#7D7C7C] mt-3'>
                    <p>About</p>
                    <p>Features</p>
                    <p>Work</p>
                    <p>Career</p>
                </div>
                <p className='mt-3'><b>FAQ</b></p>
                <div className='text-[#7D7C7C] mt-3'>
                    <p>Account</p>
                    <p>Manage Deliveries</p>
                    <p>Orders</p>
                    <p>Payment</p>
                </div>
            </div>
            <div className='text-left'>
                <p><b>HELP</b></p>
                <div className='text-[#7D7C7C] mt-3'>
                    <p>Customer Support</p>
                    <p>Delivery Details</p>
                    <p>Terms & Conditions</p>
                    <p>Privacy Policy</p>
                </div>
                <p className='mt-3'><b>RESOURCE</b></p>
                <div className='text-[#7D7C7C] mt-3'>
                    <p>Free eBook</p>
                    <p>Development Tutorial</p>
                    <p>How to - Blog</p>
                    <p>Youtube Playlist</p>
                </div>
            </div>
        </div>

        <hr className='mx-6 mb-4' />

        <p className='text-[#7D7C7C] text-[10px]'>Flying Hawk Nusantara © 2000-2023, All Rights Reserved</p>
        <img src={Payment} className='mx-auto my-2' />
    </div>
  )
}

{/* <div className='bg-black mx-6 rounded-[20px] p-6'>
    <p className='font-anton text-white text-[26px] text-left'>STAY UPTO DATE ABOUT OUR LATEST OFFERS</p>

    <input 
        type="text" 
        id="first_name" 
        className="mt-12 rounded-[20px] bg-white text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-3 pl-12"
        placeholder="Enter your email address"
        required 
    />
    <AiOutlineMail className='text-gray-900 mt-[-30px] ml-5' />
    <button 
        type="button" 
        className="w-full font-anton text-[12px] py-3 bg-white rounded-[20px] mt-7">
        Subscribe to Newslatter
    </button>
</div> */}
import React, { useState } from 'react'
import { MdDiscount } from "react-icons/md"
import { BiLogIn } from "react-icons/bi"
import { GiHanger } from "react-icons/gi"
import { RiRefreshFill } from "react-icons/ri"
import { AiFillHome, AiFillInfoCircle, AiFillShopping } from "react-icons/ai"
import './SecondNavigationBar.css';
import LogoHitam from "../../Images/LogoHitamCropCopy2.png";
import { Typography, Menu, MenuHandler, MenuList, MenuItem, Card } from "@material-tailwind/react";
import { ChevronDownIcon, RocketLaunchIcon } from "@heroicons/react/24/solid";

export const SecondNavigationBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navListMenuItems = [
    {
      title: "@material-tailwind/html",
      description: "Learn how to use @material-tailwind/html, packed with rich components and widgets.",
    },
    {
      title: "@material-tailwind/react",
      description: "Learn how to use @material-tailwind/react, packed with rich components for React.",
    },
    {
      title: "Material Tailwind PRO",
      description: "A complete set of UI Elements for building faster websites in less time.",
    },
  ];
  const renderItems = navListMenuItems.map(({ title, description }) => (
    <a href="#" key={title}>
      <MenuItem>
        <Typography variant="h6" color="blue-gray" className="mb-1 text-left">
          {title}
        </Typography>
        <Typography variant="small" color="gray" className="font-normal text-left">
          {description}
        </Typography>
      </MenuItem>
    </a>
  ));

  const navbarResponsive = `font-lexend fixed z-[100] w-full bg-white overflow-hidden flex flex-col lg:hidden gap-12  origin-top duration-700 ${!toggleMenu ? "h-0" : "h-full"}`
  const mobileIconNavbar = 'flex flex-col h-[20px] justify-between relative'
  const mobileIconLine = 'block w-[19px] h-[3px] mt-[4px] ml-[3px] rounded-[3px] bg-black transition-all duration-300'
  const onMobileIconChecked = 'absolute opacity-0 cursor-pointer z-[2] w-[40px] h-[28px] left-[-5px] top-[-3px]'
  const navbarText = 'text-[16px] mx-3 font-medium border-b-2 border-[#0060C8] border-opacity-0 hover:border-opacity-100 hover:text-[#0060C8] duration-300 cursor-pointer'
  const responsiveText = "flex items-center block py-2 pl-3 pr-4 border-b border-gray-200 text-black hover:text-white hover:bg-black rounded-[10px] lg:bg-transparent lg:text-purple-700 lg:p-0 transition-all duration-300"

  return (
    <>
      <div className='px-5 mx-auto flex justify-between py-4 items-center bg-white'>
        <div className='flex items-center'>
          <img src={LogoHitam} width={40} className="mr-2  ml-3" />
          <b>HAWK NUSANTARA</b>
          <div className='ml-5 hidden sm:hidden md:hidden lg:block lg:flex'>
            <a className={navbarText}>Home</a>
            <a className={navbarText}>About</a>
            <a className={navbarText}>On Sale</a>
            <a className={navbarText}>Arrival</a>
            <a className={navbarText}>Brand</a>
            <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
              <MenuHandler>
                  <a className={`flex items-center ${navbarText}`}>
                    Solution for
                    <ChevronDownIcon strokeWidth={2} className={`h-3 w-3 transition-transform duration-500 ml-1 ${isMenuOpen ? "rotate-180" : ""}`} />
                  </a>
              </MenuHandler>
              <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
                <Card
                  // color="blue"
                  shadow={false}
                  variant="gradient"
                  className="col-span-3 grid h-full w-full place-items-center rounded-md"
                >
                  <RocketLaunchIcon strokeWidth={1} className="h-28 w-28" />
                </Card>
                <div className="col-span-4 flex justify-start w-full flex-col gap-1">{renderItems}</div>
              </MenuList>
            </Menu>
          </div>
        </div>
        {/* Responsive Button */}
        <button className='lg:hidden' onClick={() => setToggleMenu(!toggleMenu)}>
          <div className={`menu-bars ${mobileIconNavbar}`}>
            <input className={onMobileIconChecked} type="checkbox" />
            <span className={`${mobileIconLine} origin-top-left`}></span>
            <span className={mobileIconLine}></span>
            <span className={`${mobileIconLine} origin-bottom-left`}></span>
          </div>
        </button>
        <div className='flex items-center mr-3 hidden sm:hidden md:hidden lg:block'>
          <button 
            type="button" 
            className="text-[14px] text-white bg-[#0060C8] py-1 px-5 rounded-[5px] flex items-center"
            onClick={() => window.location.href = `http://localhost:3001/login`}>
            
            <BiLogIn className='mr-2' /><b>LOG IN</b>
          </button>
        </div>
      </div>

      {/* Responsive */}
      <div className={navbarResponsive}>
        <hr />
        <ul class="flex text-left px-5 flex-col font-medium">
          <li><a href="#" class={responsiveText}><AiFillHome className='mr-3' />Home</a></li>
          <li><a href="#" class={responsiveText}><AiFillInfoCircle className='mr-3' />About</a></li>
          <li><a href="#" class={responsiveText}><AiFillShopping className='mr-3' />Marketplace</a></li>
          <li><a href="#" class={responsiveText}><MdDiscount className='mr-3' />Features</a></li>
          <li><a href="#" class={responsiveText}><RiRefreshFill className='mr-3' />Team</a></li>
          <li><a href="#" class={responsiveText}><GiHanger className='mr-3' />Contact</a></li>
          <button type="button" className="text-[14px] text-white bg-[#0060C8] py-3 px-5 rounded-[5px] flex items-center justify-center mt-3"><BiLogIn className='mr-2' /><b>LOG IN</b></button>
          <small className='text-center text-gray-400 text-[10px] mt-1'>Copyright © 2021</small>
        </ul>
      </div>
    </>
  )
}